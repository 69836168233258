import React from 'react'

export default function StripLines() {

  return (
    
    <div className="strip_lines">

        <div className="lineIn lasg_red"></div>
        <div className="lineIn lasg_blue"></div>
        <div className="lineIn lasg_yellow"></div>
        <div className="lineIn lasg_green"></div>

    </div>
    
  )

}
