import React from 'react'

export default function JudiciaryOfficials() {

  return (

    <div className="electedOfficials">

        <h1>Judiciary Officals</h1>
        <h1>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sit qui, fugit veritatis in facilis modi aliquam voluptatum fugiat mollitia? Adipisci aliquid quos cum. Perspiciatis doloremque odit hic ducimus minus quia beatae tempora dolore, vero aperiam voluptatibus, in placeat, quos dolorum asperiores ipsum deserunt at non sint voluptatem aliquid. Sed, harum.</h1>

        <h1>Judiciary Officals Page</h1>

    </div>

  )

}
