import React from 'react'
import SearchQuery from '../../components/search/searchQuery'

export default function Search() {

  return (

    <SearchQuery query = "" />

  )

}
